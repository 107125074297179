import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';

const NikkeGuidesRosSummer: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Rosanna: Chic Ocean guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_rosanna_chic.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Rosanna: Chic Ocean guide & review</h1>
          <h2>
            A guide & review for Rosanna: Chic Ocean in NIKKE: Goddess of
            Victory.
          </h2>
          <p>
            Last updated: <strong>12/07/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Rosanna: Chic Ocean" />
              <div className="employee-container for-nikke">
                <NikkeCharacter
                  mode="icon"
                  slug="rosanna-chic-ocean"
                  enablePopover
                />
              </div>
              <p>
                The infamous Arena underdog who has been dominating the dome of
                steel and sparks finally agrees to taste the excitement of
                demolishing giants. Rosanna, now in her summer outfit, is ready
                to propel her team with incredible Sustained Damage and Damage
                Taken support skills. She is also adept at salvaging bosses'
                parts, increasing the team's ATK and Parts Damage while doing
                so.
              </p>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                Rosanna: Chic Ocean (or S. Rosanna) is purely a supporter who is
                able to provide a variety of simple and straightforward buffs.
                Her burst is the main key to her usefulness, as it provides the
                team with Sustained Damage buffs and inflicts increased Damage
                Taken debuff on enemies. The latter part of the Burst is of good
                value and helps all allies in her team, while the former is key
                to her end-game use. Her main end-game use is in Solo Raids,
                which comes from the synergy between her and S. Sakura, as she
                buffs her significantly. Hence, any time S. Sakura is destroying
                enemy bosses in Raids, Rosanna (paired with Helm) might be a
                viable choice. In campaign, her performance is also impressive
                since she is not restricted to any other unit, and hence allows
                for an extra DPS or better offensive support (e.g. Maxwell).
                Apart from all of this, Rosanna also provides increased Damage
                to Parts, along with a caster's ATK buff, all of which combine
                to make her an extremely viable unit!
              </p>
              <h5>Should I pull this unit?</h5>
              <p>
                YES! Rosanna gives a multitude of reasons as to why one should
                pull her! While she is not at the top in any food chain, her
                immense flexibility allows her to be used in all available
                contents as a very decent unit. She proves to be a competitor of
                the famous 777 bunnies in campaign stages where healing is not a
                requirement since she allows for that extra DPS/support slot. In
                Solo Raids, she (paired with Helm) allows for a cheaper and
                easier-to-use (but not always better) alternative to the Tove-SG
                team or SW-Yulha team, basically landing a team 4/5 spot for
                newer or casual players. Even in Union Raids, she may be used
                over the Blanc-Noir duo by higher sync level players who do not
                require healing, and hence slotting an extra DPS in these
                situations would be more beneficial!
              </p>
              <p>
                For beginners without the Duos (Tia & Naga; Blanc & Noir) or
                Crown, Summer Rosanna will allow you to witness nearly optimal
                performance from your meta DPS, making her a good pull choice.
                In end-game campaign however, she is not considered a Meta Unit.
                As for dupes, she does not need any since she is a support. Her
                caster's ATK buff is of low value and will not increase much
                with dupes, especially due to her Supporter base stats. However,
                the MLB lobby screen is incredibly beautiful, and might be good
                enough to make a case for MLB!
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <StaticImage
                src="../../../images/nikke/reviews/rosanna_chic_skill_1.webp"
                alt="Kit"
              />
              <p>
                Rosanna: Chic Ocean (abbreviated as S. Rosanna) is a capable
                support unit with a balanced kit. There is not much to say about
                the kit other than repeating words, so we will keep it short.
                The massive Damage Taken will make her useful for weaker teams
                in Solo Raid, just inferior to the Crown & the two Duos, and her
                unique Sustained Damage buff will empower the already decent
                Summer Sakura even further. Her ATK buff is on the low side in
                terms of value, and the activation requires breaking parts.
                Thus, it may take a backseat against bosses that do not sprout
                parts regularly. However, since it is stackable, you can use the
                likes of Pepper to keep it at maximum forever.
              </p>
              <Alert variant="primary">
                <p>
                  Remember that you need to break a part to activate the ATK
                  buff stacks, otherwise Pepper cannot increase the stack count
                  from 0 → 1.
                </p>
              </Alert>
              <p>
                The Parts Damage will significantly boost the team's DPS against
                bosses that have a lot of parts, but if the boss doesn't have
                parts, then it is also useless. All in all, her kit is nothing
                special, but she would work just sufficiently for any kind of
                situation.
              </p>
              <p>
                One disadvantage of using S. Rosanna, especially for veterans,
                is the fact that she is a non-healer B2. In the current trend,
                we usually prefer B1 CDR + B2 Healer + Three B3 DPS for our
                compositions. Or, in some cases such as the SG team, no CDR at
                all. Hence, S. Rosanna being a B2 Buffer will put her in a
                similar category as Novel. With that, we are limited to these
                less preferred alternatives, both come at a cost of DPS loss:
              </p>
              <ul>
                <li>
                  Using B2 CDR like Dolla or Summer Helm, together with a B1
                  Healer like Pepper, so we can keep deploying 3 B3 DPS.
                </li>
                <li>
                  Using B1 CDR with B3 Healer like Helm (ideal) or an extra B1
                  Healer like Bunny Alice/Rapunzel/Noise.
                </li>
              </ul>
              <p>
                As far as synergy goes, it might be desirable to either pair her
                with Pepper + Dolla/SHelm (as Pepper will help keep S. Rosanna's
                ATK skill ramp up much more quickly while maintaining its
                otherwise volatile stacks), or run her with Helm. With Helm,
                your second DPS choice has restricted choices since you will
                have to choose a unit who can deal good damage without Bursting,
                in which case Modernia and Scarlet are the top choices.
              </p>
              <p>
                As the meta develops, and depending on the boss we are facing,
                better tech/teams with higher DPS potential are usually
                uncovered. You may want to keep an eye out for those.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Usage Analysis and Tier List" />
              <h5>
                Campaign (LD) - <strong className="ss">SS</strong> | Campaign
                (HD) - <strong className="s">S</strong>
              </h5>
              <p>
                S. Rosanna can be used to high effect in Campaign due to her
                Damage Taken debuff, which has a decent value and is comparable
                to Blanc. In Low Deficit, she directly competes with the Bunny
                Duo of Blanc and Noir since healing or shielding is usually
                never a requirement. Hence, slotting in an extra DPS instead of
                using a support SG like Noir will be more effective in most
                cases since this will allow for a higher damage ceiling! The
                extra FLEX slot that she provides can be considered similar to
                what Crown provides since neither of them are locked to another
                duo, such as seen with Tia and Blanc. While not as strong as
                Crown, S. Rosanna can definitely compete with Blanc here!
              </p>
              <p>
                In higher deficits, S. Rosanna's problems start becoming more
                apparent. The need for shields and/or heals becomes a necessity
                in most stages. Not only that, we start to realize that both of
                her Skills basically provide nothing in Campaign. There are no
                Parts in Campaign, hence the entirety of Skill 1 and the first
                part of Skill 2 are rendered useless. The second part of Skill 2
                is Sustained Damage, but due to its small value and under
                Rosanna's supporter stats, it will be negligible. At the end,
                what's left is her Damage Taken debuff.
              </p>
              <p>
                If a healer is paired with S. Rosanna, she definitely becomes
                worse than the support duo of Blanc and Noir due to their only
                being 2 DPS units. S. Sakura is not as strong of a unit in
                Campaign since her Sustained Damage is not strong enough to kill
                multiple raptures, and it is usually not ideal to burst with
                Helm in Campaign anyways. A strong contender for the flex spot
                can be Naga, but she has better pairings with Crown and Tia. In
                higher deficits, Rosanna cannot be considered a meta unit, but
                is still a decent choice if you lack other supports.
              </p>
              <h5>
                Boss (Solo) - <strong className="s">S</strong> | Boss (Adds) -{' '}
                <strong className="s">S</strong>
              </h5>
              <p>
                Everything that S. Rosanna cannot do in Campaign, she can
                against Bosses! Sustained Damage dealers (currently only S.
                Sakura) are now considered as viable units, and Bosses often
                come with Parts! Her resurrection provides ~13% ATK buff (when
                converted to Attacker Stats) and some Parts Damage buffs. The
                latter has a 50% uptime due to her Skill 2 having the same exact
                buff with the same exact value, and can be considered as a
                decent damage buff, especially against Bosses with regenerating
                boss parts! Her Burst Skill's Sustained Damage buff is now
                viable as well due to S. Sakura being relevant!
              </p>
              <p>
                But, now remains one very serious problem. S. Rosanna does not
                heal, and neither is she a shielder like Crown. This problem may
                be circumvented by using units like Helm, who provides offensive
                Attack Damage buffs. Against bosses with parts, this pairing can
                be considered as a side-grade to the 777 Bunny Duo, just that
                instead of Ammo Buffs, we receive Bonus Damage to Parts and
                Sustained Damage buffs. Against Bosses where parts break often
                and regenerate (such as in the current Golden Kraken Solo Raid),
                Rosanna + Helm will be able to provide a higher damage ceiling
                than what the Bunny Duo can provide, and will be the better
                choice of supports. However, this does lead to them being a
                situational support structure.{' '}
              </p>
              <p>
                Against Bosses with additional, smaller Raptures, her
                performance does not falter, but again, the problem lies in the
                fact that Rosanna cannot heal. Other competitors are able to
                heal and provide offensive buffs together, which leads to
                Rosanna having a hard time trying to slot into the the top
                teams.
              </p>
              <h5>
                PVP - <strong className="b">B</strong>
              </h5>
              <p>
                {' '}
                In PvP, Rosanna can be considered as a side-grade to Poli or
                Crown, and a direct downgrade from Blanc. She does exactly what
                Blanc does, just without providing the Indomitability. She lacks
                Burst Gen and special skills that matter in PvP, and hence
                cannot be considered as a meta unit in PvP, albeit still usable.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <p>
                If we talk about efficiency, there is no need to OL Rosanna's
                gear as her caster's ATK buff has a rather low value. However,
                efficiency is rarely the case for tryhards of Solo Raids, so
                while we recommend not OL-ing her for most players, competitive
                players might benefit from the increased base stats. Other than
                that, OL-ing her should only be done for one other reason: CP
                padding. Be it Campaign, Tribe Tower or PvP, S. Rosanna does not
                need overload gear, but if you want to CP pad, go for it. She
                does not need any specific gear to work properly.
              </p>
              <Alert variant="primary">
                <p>
                  The strength of her ATK buff is based on caster's ATK, and
                  since she is a support, it will only be around ~13% if you
                  overload her.
                </p>
              </Alert>
              <ul>
                <li>
                  <strong>Essential:</strong> -
                </li>
                <li>
                  <strong>Ideal:</strong>
                </li>
                <li>
                  <strong>Passable:</strong>
                </li>
                <li>
                  <strong>Priority:</strong> Low
                </li>
              </ul>
              <h5>Skill Investments</h5>
              <ul>
                <li>
                  <strong>Skill 1: 4-10</strong>
                </li>
                <ul>
                  <li>
                    {' '}
                    Skill 1 is of low Priority to upgrade for a couple of
                    reasons. The first is that the initial Parts Damage buff is
                    a one-time buff, and it has an extremely low uptime since it
                    is active for only 15 seconds out of 180. The second is that
                    the ATK buff that S. Rosanna provides has a low value
                    overall, but damage is damage, so only go for it if you are
                    a competitive player in Solo Raids. Otherwise, 4 is a good
                    stopping point.
                  </li>
                </ul>
                <li>
                  <strong>Skill 2: 4/7/10</strong>
                </li>
                <ul>
                  <ul>
                    <li>
                      {' '}
                      Rosanna's Skill 2 is where the majority of her Parts
                      Damage buff lies. Any DPS that Bursts first will be able
                      to take full advantage of this. Any DPS that does not need
                      to Burst to deal damage (e.g Modernia or Scarlet) may also
                      take full advantage of this buff as well. Strictlg timed
                      burst activations with Snow White can also help SW take
                      advantage of this buff!
                    </li>
                  </ul>
                </ul>
                <li>
                  <strong>Burst Skill: 7~10</strong>
                </li>
                <ul>
                  <li>
                    Her Burst is the major part of her skill. The massive Damage
                    Taken debuff on enemies benefits all kinds of allies, and
                    the Sustained Damage effectively increases her synergy with
                    S. Sakura.
                  </li>
                </ul>
              </ul>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="rosanna-chic-ocean" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_4_sm.png"
                alt="Guide"
              />
              <p>
                Rosanna does not particularly require any cube. Any generic cube
                works on her, but Bastion will allow her to shoot more bullets
                over 3 minutes. Despite that, it is recommended to use the
                highest level cube available for the base stat bonuses to help
                with her caster's ATK buff.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: Summer Solstice</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                    <NikkeCharacter mode="icon" slug="volume" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rosanna-chic-ocean"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="sakura-bloom-in-summer"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="helm" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="modernia" enablePopover />
                    <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                The two summer units have high synergy together! S. Rosanna
                buffs S. Sakura extremely well. Her Damage Taken debuff, paired
                with extra Parts Damage buffs, as well as the Sustained Damage
                buff are all highly appreciated. Combined, they allow S. Sakura
                to deal immense damage to opponents. This buffs S. Sakura beyond
                what most other teams can, even higher than the famous bunny
                duo!
              </p>
              <h5>Team #2: 3 DPS Better Than 2 DPS</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="d-killer-wife"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rosanna-chic-ocean"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
              </div>
              <p>
                This team can effectively be used in Campaign, as well as
                against Bosses which do little or no damage to your team. The
                aim in Campaign will be not to get hit, which can prove to be a
                skill barrier. While it may be less comfortable, it is
                definitely a higher DPS ceiling than the Bunny Duo team. Making
                the most use of your cover and i-framing skills will be required
                against some harder to beat stages. The flex spot can be filled
                by another B3 DPS such as Modernia, Scarlet, or even offensive
                supporters like Maxwell. Naga or other healers like Rapunzel may
                also fill in this slot in low deficit gameplay.
              </p>
              <h5>Team #3: Stack Overload</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="pepper" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rosanna-chic-ocean"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="sakura-bloom-in-summer"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                Team catering to incomplete rosters, who may take advantage of
                current and upcoming Summer Banners. Here, we try to take
                advantage of Pepper's ability to increase stacks of stackable
                buffs, which in this case will be Rosanna's caster's ATK buff.
                Remember, to activate this buff, you need to destroy a Boss Part
                first, so the Boss needs to have parts. As Pepper is another
                wind unit, this team can perform well with Dolla against Wind
                Weak Bosses with Parts. This team can be expected to perform
                decently against Iron Weak Bosses with Parts as well, taking
                advantage of Summer Helm's permanent Damage Taken debuff,
                Pepper's healing and stacking ability, as well as Rosanna's
                offensive buffs to maximize damage output for the 2 B3s.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="PvP Team Compositions" />
              <h5>Team #1: 2~2.5~3RL Simple Nuke</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="sakura-bloom-in-summer"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rosanna-chic-ocean"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="centi" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="anis" enablePopover />
                    <NikkeCharacter mode="icon" slug="emilia" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                Scarlet team is 2.5RL at base, 2RL with feed or Quantum Cubes.
                S. Sakura team is 3RL at base, 2.5 with feed. Fast-buffed Nukes
                to wipe off slower teams quickly. Expensive, but it saves Blanc.
              </p>
              <h5>Team #2: 3RL Simple Nuke </h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="sakura-bloom-in-summer"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rosanna-chic-ocean"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="centi" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                {' '}
                Slower Team, can be risky to use since no protection against
                opponent nukes, something that Blanc is able to provide. Can be
                used effectively against slow, 4RL S.Anis teams.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Massive Damage Taken debuff on enemies.</li>
                        <li>
                          Powerful Sustained Damage buff - strong synergy with
                          S. Sakura.
                        </li>
                        <li>Provides decent Parts Damage buff.</li>
                        <li>
                          Quite flexible, can be used with different B3s, and
                          allows for extra B3.
                        </li>
                        <li>Insanely hot with a very lewd MLB lobby screen.</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Lacks healing, hence tough to fit into current meta.
                        </li>
                        <li>
                          ATK buff is of low value and stacking is dependent on
                          breaking parts.
                        </li>
                        <li>
                          Without parts, her Skill 1 and half of Skill 2 are
                          entirely useless.
                        </li>
                        <li>
                          Cannot open Nikke on your phone in public if you have
                          her as your lobby screen.
                        </li>
                        <li>Will age-restrict Youtube videos.</li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-5">PVP Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesRosSummer;

export const Head: React.FC = () => (
  <Seo
    title="Rosanna: Chic Ocean guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Rosanna: Chic Ocean in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
